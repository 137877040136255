<template>
  <el-dialog
    :visible="visible"
    title="创建同步任务"
    @before-close="beforeClose"
    @close="closeDialog"
    :append-to-body="true"
  >
    <el-form
      ref="formRef"
      :model="formModel"
      :rules="formRules"
      label-width="200px"
    >
      <el-form-item label="帐号" prop="accountsIds">
        <account-sel
          ref="accountSel"
          :collapseTags="true"
          :initData="accountIds"
          @change-account="changeAccount"
          @post-init="changeAccount"
        ></account-sel>
      </el-form-item>
      <!-- <el-form-item label="加载账户方式" prop="loadAccountFromDb">
        <el-radio-group v-model="formModel.loadAccountFromDb">
          <el-radio :label="0">从fb加载账户</el-radio>
          <el-radio :label="1">从数据库加载账户</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="日期范围" prop="daterange">
        <date-picker
          :initDateRange="defDateRange"
          @change-date="handleChangeDate"
        ></date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="syncTask">同步</el-button>
        <el-button @click="cancelSyncTask">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { startRecentJob } from "@/api/taskManage";
import { mapState } from "vuex";
import accountSel from "@/views/adManagement/components/accountSel";
import datePicker from "@/views/components/datePicker";
import _ from "lodash";
export default {
  name: "syncTask",
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    accountSel,
    datePicker,
  },
  data() {
    return {
      formModel: {
        accountsIds: [],
        // loadAccountFromDb: 1,
        daterange: [],
      },
      formRules: {
        accountsIds: {
          required: true,
          message: "账户必填！",
          trigger: ["blur", "change"],
        },
        loadAccountFromDb: {
          required: true,
          message: "加载帐户方式必填！",
          trigger: "blur",
        },
        daterange: {
          required: true,
          message: "日期范围必填！",
          trigger: "blur",
        },
      },
      defDateRange: [],
    };
  },
  computed: {
    ...mapState("batchAccount", ["accountsOptions","accountIds"]),
    numIds() {
      let arr = this.formModel.accountsIds.map((item) => item[0]);
      let arr2 = Array.from(new Set(arr));
      return arr2;
    },
    accountsIds() {
      let arr = this.formModel.accountsIds.map((item) => item[1]);
      return arr;
    },
  },
  methods: {
    beyondTip() {
      this.$message({
        type: "info",
        message: "选择帐户数量过多，请减少选中的帐户数量！",
      });
    },
    limitAccountsCount() {
      let numStr = this.numIds.join(","),
        accountsStr = this.whetherCheckAllAccounts().join(",");
      if (numStr.length > 250 || accountsStr.length > 250) {
        this.beyondTip();
      }
    },
    whetherCheckAllAccounts() {
      let nums = _.cloneDeep(this.numIds),
        accounts = _.cloneDeep(this.accountsIds);
      nums.forEach((num) => {
        let obj = this.accountsOptions.find((item) => item.id == num),
          numAccounts = [];
        if (obj) {
          numAccounts = obj.fbAdAccounts;
        }
        if (numAccounts.length) {
          let checkAll = numAccounts.every((item) =>
            accounts.some((a) => a == item.id)
          );
          if (checkAll) {
            accounts = accounts.filter(
              (item) => !numAccounts.some((n) => n.id == item)
            );
          }
        }
      });
      console.log("whetherCheckAllAccounts", accounts);
      return accounts;
    },
    startRecentJob() {
      this.$showLoading();
      let params = {
        thirdLoginIds: this.numIds.join(","),
        onlyAccountIds: this.whetherCheckAllAccounts().join(","),
        id: parseInt(new Date().getTime() / 1000 / 30),
        loadAccountFromDb: 1,
        dateStart: this.formModel.daterange[0],
        dateEnd: this.formModel.daterange[1],
      };
      console.log("startRecentJob-params", params);
      return startRecentJob(params).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "创建同步任务成功！",
          });
          this.$emit("close-sync-task");
        }
      });
    },
    beforeClose() {
      this.$ref.formRef.resetFields();
    },
    closeDialog() {
      this.$emit("close-sync-task");
    },
    changeAccount(val) {
      this.formModel.accountsIds = val && val.length ? [...val] : [];
    },
    handleChangeDate(val) {
      console.log("日期改变,val", val);
      if (val.length) {
        this.formModel.daterange = [...val];
        console.log("this.formModel.daterange", this.formModel.daterange);
      }
    },
    cancelSyncTask() {
      this.$emit("close-sync-task");
    },
    syncTask() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.limitAccountsCount();
          console.log(
            "this.numIds,this.whetherCheckAllAccounts()",
            this.numIds,
            this.whetherCheckAllAccounts()
          );
          this.startRecentJob();
        }
      });
    },
  },
};
</script>

<style>
</style>